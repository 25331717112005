import { GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline'

const features = [
    {
      name: 'Phase 1',
      description:
        'Release 50 lifetime access tokens for users to use on API Brew. API Brew is an iOS App that lets you build your own content feed.',
      icon: LightningBoltIcon,
    },
    {
      name: 'Phase 2',
      description:
        'Incorporate Login With Phantom on the App for more better integration and easier access to your NFT dashboard on Mobile.',
      icon: LightningBoltIcon,
    },
    {
      name: 'Phase 3',
      description:
        'Exclusive Casks built for Token holders be it Basic, Elite or Pro. Faster access to new features and better dashboards for viewing insights of your portfolio.',
      icon: LightningBoltIcon,
    },
]


export default function Roadmap() {
    return (
      <div className="py-12 bg-white">
        <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
            {features.map((feature) => (
              <div key={feature.name}>
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="mt-5 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    )
  }