import React, { useEffect, useState } from "react";
import { Popover } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import phantom from './../assets/phantom.svg';
import logo from './../assets/logo.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Header = () => {

	// State
	const [walletAddress, setWalletAddress] = useState(null);

	// Actions
	const checkIfWalletIsConnected = async () => {
	  try {
		const { solana } = window;
  
		if (solana) {
		  if (solana.isPhantom) {
			console.log("Phantom wallet found!");
			const response = await solana.connect({ onlyIfTrusted: true });
			console.log(
			  "Connected with Public Key:",
			  response.publicKey.toString()
			);
  
			/*
			 * Set the user's publicKey in state to be used later!
			 */
			setWalletAddress(response.publicKey.toString());
		  }
		} else {
		  alert("Solana object not found! Get a Phantom Wallet 👻");
		}
	  } catch (error) {
		console.error(error);
	  }
	};
  
	const connectWallet = async () => {
	  const { solana } = window;
  
	  if (solana) {
		const response = await solana.connect();
		console.log("Connected with Public Key:", response.publicKey.toString());
		setWalletAddress(response.publicKey.toString());
	  }
	};
  
	const renderNotConnectedContainer = () => (
		<button
			className="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
			onClick={connectWallet}
		>
			<img
                alt="Twitter Logo"
                className="twitter-logo w-8"
                src={phantom}
            />

			Connect Wallet
		</button>
	);

	const renderConnected = () => (
		<button
			className="ml-6 inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
		>
			<img
                alt="Twitter Logo"
                className="twitter-logo w-8"
                src={phantom}
            />

			{walletAddress.substring(0, 8) + "..."}
		</button>
	)
  
	useEffect(() => {
	  const onLoad = async () => {
		await checkIfWalletIsConnected();
	  };
	  window.addEventListener("load", onLoad);
	  return () => window.removeEventListener("load", onLoad);
	}, []);

	  
  return (
		  <>
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
			  <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
				<div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
				  <div className="flex-shrink-0 flex items-center">
					<a href="#">
					  <img
						className="block h-16 w-16"
						src={logo}
						alt="Workflow"
					  />
					</a>
				  </div>
				</div>
				<div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
				  <div className="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
					<div className="w-full">
					  
					  <div className="relative py-4 pl-10 pr-3">
					  </div>
					</div>
				  </div>
				</div>

				<div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
						
					{walletAddress && renderConnected()}

					{!walletAddress && renderNotConnectedContainer()}
				  
				</div>
				
			  </div>
			</div>
		  </>
  )
}

export default Header;
