import React, { useEffect, useState } from 'react';
import './CountdownTimer.css';

const CountdownTimer = ({ dropDate }) => {
    // State
    const [timerString, setTimerString] = useState('');

    // Our useEffect will run on component load
    useEffect(() => {
        console.log('Setting interval...');

        // Use setInterval to run this piece of code every second
        const interval = setInterval(() => {
        const currentDate = new Date().getTime();
        const distance = dropDate - currentDate;

        // Here it's as easy as doing some time math to get the different properties
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // We have our desired output, set it in state!
        setTimerString(`${days}d ${hours}h ${minutes}m ${seconds}s`);

        // If our distance passes zero this means that it's drop time!
        if (distance < 0) {
            console.log('Clearing interval...');
            clearInterval(interval);
        }
        }, 1000);

        // Anytime our component unmounts let's clean up our interval
        return () => {
        if (interval) {
            clearInterval(interval);
        }
        };
    }, []);

    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">Candy Drop Starting In</h2>
                    <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                        {timerString && <p className="timer-value">{`⏰ ${timerString}`}</p>}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CountdownTimer;