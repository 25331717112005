/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/outline'
import basic from '../assets/0.png';
import pro from '../assets/1.png';
import elite from '../assets/2.png';

const pricing = {
  tiers: [
    {
      title: 'Basic',
      description: 'Lifelong access to unlimited brews.',
      features: [
        'Unlimited Brews', 
        '1 Year reports for your NFT portfolio reports via email.'
      ],
      image: basic,
      mostPopular: false,
    },
    {
      title: 'Pro',
      description: 'Lifelong access to unlimited brews and brewery.',
      features: [
        'Unlimited Brews.', 
        '3 Year reports for your NFT portfolio reports via email.',
        'Access to Brewery.',
        'Supports adding up to 10 casks of your own.'
      ],
      image: pro,
      mostPopular: true,
    },
    {
      title: 'Elite',
      description: 'Lifelong access to unlimited brews, casks and more.',
      features: [
        'Unlimited Brews.', 
        'Lifelong NFT portfolio reports via email.',
        'Access to Brewery.',
        'Unlimited casks.',
        'Access to Editor Choice Casks.'
      ],
      image: elite,
      mostPopular: false,
    },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Tokens() {
  return (
    <div className="max-w-7xl mx-auto py-24 px-4 bg-white sm:px-6 lg:px-8">
      <h2 className="text-3xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl text-center">
        Mint your Lifetime membership for 0.6 SOL
      </h2>
      <p className="mt-6 text-xl text-gray-500 text-center">
        Only 50 of these exclusive passes exists and gives you lifetime access and tons of benefits. API Brew supports tons of functionality with NFT. It is right now the only app that supports seeing NFT portfolio view for both Ethereum & Solana.
      </p>

      {/* Tiers */}
      <div className="mt-24 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
        {pricing.tiers.map((tier) => (
          <div
            key={tier.title}
            className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
          >
            <div className="flex-1">
              <img className="object-cover shadow-lg rounded-lg" src={tier.image} alt="" />
              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-5xl font-extrabold tracking-tight">{tier.title}</span>
              </p>
              <p className="mt-6 text-gray-500">{tier.description}</p>

              {/* Feature list */}
              <ul role="list" className="mt-6 space-y-6">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex">
                    <CheckIcon className="flex-shrink-0 w-6 h-6 text-indigo-500" aria-hidden="true" />
                    <span className="ml-3 text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
